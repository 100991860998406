export default () => {
    $(document).find('.js-clock-container').each( (index, elem) => {
        if ($(elem).is (":visible")){
            let $this = $(elem),
            untilData = $this.data('clock'),
            days = $this.data('days'),
            hours = $this.data('hours'),
            minutes = $this.data('minutes'),
            finishDate = new Date(untilData).getTime(),
            clockContainer = $( $this.data('place') ),
            now = ((new Date().getTime())/1000),
            countdown = null

            if(isNaN(finishDate ) || ( finishDate / 1000).toFixed(0) - now < 0 )
                return clockContainer.addClass('hidden')

            countdown = ( finishDate / 1000).toFixed(0) - now
            FlipClock.Lang.Custom = { days, hours, minutes };
            var opts = {
                clockFace: 'DailyCounter',
                countdown: true,
                minimumDigits : 2,
                showSeconds: false,
                language: 'Custom'
            };
            countdown = Math.max(1, countdown);
            clockContainer.FlipClock(countdown, opts);
        }
    });

     $(document).find('.js-clock-container-with-seconds').each( (index, elem) => {
        if ($(elem).is (":visible")){
            let $this = $(elem),
            untilData = $this.data('clock'),
            days = $this.data('days'),
            hours = $this.data('hours'),
            minutes = $this.data('minutes'),
            seconds = $this.data('seconds'),
            finishDate = new Date(untilData).getTime(),
            clockContainer = $( $this.data('place') ),
            now = ((new Date().getTime())/1000),
            countdown = null

            if(isNaN(finishDate ) || ( finishDate / 1000).toFixed(0) - now < 0 )
                return clockContainer.addClass('hidden')

            countdown = ( finishDate / 1000).toFixed(0) - now
            FlipClock.Lang.Custom = { days, hours, minutes, seconds };
            var opts = {
                clockFace: 'DailyCounter',
                countdown: true,
                minimumDigits : 2,
                showSeconds: true,
                language: 'Custom'
            };
            countdown = Math.max(1, countdown);
            clockContainer.FlipClock(countdown, opts);
        }
    });

    $(function () {
        if ($(".flip-clock-wrapper").is(":visible")) {
            $('.section-image_title').removeClass('hidden');
        }
    });
}

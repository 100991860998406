import Clock from '../../Clock'

class Tabs {
    constructor() {
        this.selectors = {
            document: document,
            storiesTab: '.js-stories-tab',
            story: '.blog__item',

        };
        this.init();
        this.events();
    };

    init() {
        var _this = this;
        _this.getNodes();

    }

    getNodes() {
        var _this = this;

        this.nodes = {

        };

        $.each(this.selectors, function(key, selector) {
            _this.nodes[key] = $(selector);
        });
    }

    events() {
        var _this = this;

        _this.nodes.document.on('shown.bs.tab', _this.selectors.storiesTab, function(event) {
            event.preventDefault();
            const $this = $(event.currentTarget);
            const href = $this.attr('href');
            const hash = $this.data('hash');
            const id = $(hash);
            const url = $this.data('get-story');
            _this.getStory(url, id);
        });
    }

    getStory(url, id) {
        const _this = this;
        if (id.find(_this.selectors.story).length === 0) {
            $.ajax(url, {
                method: 'GET',
                success(resp) {
                    id.html(resp.html);
                    Clock();
                },
            });
        };
    }
}
new Tabs();
